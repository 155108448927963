<template lang="pug">
	.d-flex.flex-column.change-bot
		.d-flex.position-relative.change-bot__control(v-if="element == 'name'")
			my-input.w-100(
				v-model.trim="firstName"
				:label="$t('bots.settings.name')"
				classInput="change-bot__input-name"
				:maxlength="64"
				:isError="firstName.length < 1"
				:errorFeedback="firstName.length < 1 ? $t('errors.required.givenNameBot') : ''"
			)

		.d-flex.position-relative.change-bot__control(v-if="element == 'info'")
			my-input.w-100(
				v-model.trim="shortDescription"
				:label="$t('bots.settings.aboutBot')"
				classInput="change-bot__input-name"
				:maxlength="120"
			)

		.d-flex.position-relative.change-bot__control(v-if="element == 'desc'")
			my-text-area.w-100(
				v-model.trim="description"
				:label="$t('bots.settings.description')"
				classInput="change-bot__textarea-desc"
				:maxlength="512"
			)
		
		.d-flex.position-relative.change-bot__control(v-if="element == 'start-message'")
			my-text-area.w-100(
				v-model.trim="startMessage"
				:label="$t('bots.settings.startMessage')"
				classInput="change-bot__textarea-desc"
				:maxlength="3000"
			)

		.d-flex.position-relative.change-bot__control(v-if="element == 'token'")
			my-input.w-100(
				v-model.trim="token"
				:label="$t('bots.settings.APIToken')"
				:errorFeedback="error"
				@blur='validationAPIToken'
				@input='validationAPIToken'
				classInput="change-bot__input-token"
				icon="icon-link"
				:maxlength="46"
			)

		.btn.btn-primary.change-bot__save(:class="{'disabled': isLoading || isDisabled || firstName.length < 1}" @click="save")
			.loader(v-if="isLoading")
			span.b2(v-else) {{ $t('forms.save') }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import MyInput from '@/components/UI/MyInput';
import MyTextArea from '@/components/UI/MyTextArea';

export default {
    name: 'ChangeBot',
    components: {
        MyInput,
        MyTextArea,
    },
    props: {
        element: {
            default: '',
            type: String,
        },
    },
    data: () => ({
        isLoading: false,
        firstName: '',
        shortDescription: '',
        description: '',
        startMessage: '',
        token: '',
        error: '',
        isDisabled: false,
    }),
    computed: {
        ...mapGetters({
            bot: 'project/bot',
            item: 'project/opened',
            lastOpenedProjectId: 'project/lastOpenedProjectId',
        }),
    },
    created() {
        this.firstName = this.bot.firstName || '';
        this.shortDescription = this.bot.shortDescription || '';
        this.description = this.bot.description || '';
        this.startMessage = this.bot.startMessage || '';
        this.token = this.bot.token || '';
    },
    methods: {
        ...mapActions({
            editBot: 'bot/edit',
            getProject: 'project/id',
        }),
        save() {
            this.isLoading = true;

            let params = {
                id: this.bot.id,
                firstName: this.firstName,
                shortDescription: this.shortDescription,
                description: this.description,
                startMessage: this.startMessage,
                token: this.token,
            };

            this.editBot(params)
                .then(i => {
                    this.getProject({ id: this.lastOpenedProjectId });
                    this.isLoading = false;
                    this.$emit('close');
                    if (this.element == 'name') {
                        this.$notify('success', this.$t('success.nameChanged'));
                    } else if (this.element == 'info') {
                        this.$notify('success', this.$t('success.botInfoChanged'));
                    } else if (this.element == 'desc') {
                        this.$notify('success', this.$t('success.descChanged'));
                    } else if (this.element == 'start-message') {
                        this.$notify('success', this.$t('success.startMessageChanged'));
                    } else if (this.element == 'token') {
                        this.$notify('success', this.$t('success.apiTokenChanged'));
                    }
                })
                .catch(v => {
                    this.isLoading = false;

                    if (v == 'Unauthorized') this.$notify('error', this.$t('errors.APItokenNotValid'));

                    if (v != 'Unauthorized') this.$notify('error', v);
                });
        },
        validationAPIToken() {
            const regex = /^[0-9]{8,10}:[a-zA-Z0-9_-]{35}$/i;

            this.isDisabled = !(this.token != null && this.token != '');
            this.error = this.token == '' ? this.$t('errors.enterAPItoken') : '';

            if (this.token && this.token.length > 0) {
                if (!regex.test(this.token)) {
                    this.isDisabled = true;
                    this.error = this.$t('errors.alpha.noValidToken');
                } else {
                    this.error = '';
                    this.isDisabled = false;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.change-bot {
    margin-top: -6px;

    @include respond-below(sm-new) {
        margin-top: 0;
    }

    &__save {
        margin-top: 25px;
        color: var(--foreground-color);

        @include respond-below(sm-new) {
            margin-top: 20px;
        }
    }
}
</style>
